import React from "react"
import Container from "../../components/container"
import ImageFixed from "../../components/imageFixed"
import SEO from "../../components/seo"
import { Link, graphql } from "gatsby"

//NotFoundPage
const notFoundGeneral = ({ data }) => {
    const imageFixed = data.imageSharp.fixed

    return (
    <Container>
        <SEO title="KINESIÓLOGA NO ENCONTRADA" />
        <ImageFixed fixed={imageFixed} />
        <div style={{
            position: `absolute`,
            top: `25px`,
            left: `25px`,
            textShadow: `2px 2px 8px #000`,
            color: `white`
        }}
        >
            <h1>KINESIÓLOGA NO ENCONTRADA</h1>
            <div>Probablemente el enlace que usaste es erróneo :(</div>
            <div>Por favor corrige el texto y <strong>vuelve a entrar</strong> ;)</div>
            <Link
                to="/"
                rel="noopener noreferrer"
                style={{
                    backgroundColor: `#E0006C`,
                    width: `165px`,
                    height: `40px`,
                    borderRadius: `25px`,
                    display: `flex`,
                    alignItems: `center`,
                    justifyContent: `center`,
                    color: `white`,
                    textDecoration: `none`,
                    marginTop: `25px`,
                    textShadow: `none`,
                }}                   
            >
                ATRÁS
            </Link>
        </div>
    </Container>
    )
}

export default notFoundGeneral

export const notFoundGeneralQuery = graphql`
    query NotFoundGeneralQuery {
        imageSharp(fixed: {originalName: {eq: "pagina-no-encontrada.jpg"}}) {
            fixed {
                ...GatsbyImageSharpFixed
            }
        }
    }
`
